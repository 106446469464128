@import 'util';


$global-lineheight: 1.618;
$hollow-button-color: #dfdddd;
$contacts-background-color:#369;
$contacts-input-background-color:darken($contacts-background-color, 20%);

$main-bg-color:rgb(230, 80, 50);
//$main-bg-color:rgb(230, 50, 50);


$font-path: "../fonts/foundation-icons/foundation-icons";

html, body{
  height: 100%;
  padding:0;
  margin: 0;
  background: $contacts-background-color;
}
@font-face {
  font-family: "foundation-icons";
  src: url("#{$font-path}.eot");
  src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}.woff") format("woff"),
  url("#{$font-path}.ttf") format("truetype"),
  url("#{$font-path}.svg#fontcustom") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "../fonts/foundation-icons/foundation-icons.css";




header {
  position: relative;
  //height:20rem;
  min-height: 600px;
  //background: $main-bg-color;
  background: url('/assets/img/mountains.jpg') bottom center no-repeat;
  background-size: cover;
  background-position: fixed;
  background-attachment: fixed;
  //@include box-shadow(0 0 3px 1px #333);

  color: #dfdddd;
  text-shadow: 1px 1px 3px #333;
  margin:0;
  height: 100%;
  text-align: center;
  .mainlogo{
    margin-top: $global-lineheight*1rem;
    margin-bottom: $global-lineheight*2rem;
    text-align: center;
    img{
      margin:0 auto;
      height:$global-lineheight*4rem;
    }
  }
  .maintitle {
    font-family: 'Muli', sans-serif;
    font-size: 2*$global-font-size;
    line-height: 1;
    margin-top: 0;
    margin-bottom: $global-lineheight*1rem;
  }
  .subtitle {
    font-family: 'Muli', sans-serif;
    font-size:1.2*$global-font-size;
  }
  .contactus{
    margin-top:$global-lineheight*2rem;
    text-align:center;
    a{
      margin:auto;
    }
  }

}
.tmenu{
  height: 100%;
}
.button.hollow{
  font-family: 'Muli', sans-serif;
  color:$hollow-button-color;
  border-color:$hollow-button-color;
  &:hover{
    color:orange;
    border-color:orange;
  }
  &:focus{
    color:orange;
    border-color:#fff;
  }
  &:visited:active,&:active{
    color:orange;
    border-color:#fff;
  }
  &:visited{
    color:#fff;
  }
}
section.contact-us{
  background:$contacts-background-color;
  //background: url('/assets/img/mountains_blur.jpg') bottom center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height:100%;
  min-height: 600px;
  text-align:center;
  form{
    margin-top:$global-lineheight*3rem;
    margin-bottom:$global-lineheight*3rem;
    @include placeholder{
      color:#99d;
    }
    @include placeholder{
      color:#999;
    }
    input, textarea{
      color:#ddd;
      resize:none;
      background:$contacts-input-background-color;
      //background:none;
      border:none;
      //border:1px solid #ddd;
      @include border-radius(3px);
      &:focus{
        background:$contacts-input-background-color;
        //background:none;
      }
    }
    a{
      margin:auto;
    }
  }
}

// social section
section.social{
  position:fixed;
  height: 10rem;
  top:50%;
  margin-top:-5rem;
  right:40px;
  ul{
    &>li{
      list-style: none;
      line-height:$global-lineheight*3;
      width:100%;
      &>a{
        display:inline-block;
        padding:0;
        margin: 0;
        color:#eee;
        @include border-radius(50%);
        border:2px solid #ddd;
        line-height:$global-lineheight*1.2rem;
        font-size:1.4rem;
        height:$global-lineheight*1.2rem;
        width:$global-lineheight*1.2rem;
        &>i{
          display: inline-block;
          padding:0;
          width:$global-lineheight*1.2rem;
          text-align: center;
          position:relative;
          top:-1px;
          left:-2px;
        }
      }
    }
  }
}

// medium and up
@include breakpoint(medium) {
  header{
    .mainlogo{
      margin-top: $global-lineheight*3rem;
      margin-bottom: $global-lineheight*2rem;
      text-align: center;
      img{
        margin:0 auto;
        height:$global-lineheight*4rem;
      }
    }

  &>.maintitle{
    line-height: 1;
    font-size: 5*$global-font-size;
    margin-top: 0;
    margin-bottom: $global-lineheight*6rem;
  }
    .contactus{
      margin-top:$global-lineheight*3rem;
    }
}

  // social
  section.social{
    ul>li{
      line-height:$global-lineheight*4;
      &>a{
        border:5px solid #ddd;
        font-size:$global-lineheight*1rem;
        line-height:$global-lineheight*2rem;
        height:$global-lineheight*2rem;
        width:$global-lineheight*2rem;
        &>i{
          width:$global-lineheight*2rem;
          top:-2.5px;
          left:-5px;

        }
      }
    }
  }
} // medium and up end
.g-recaptcha{
  margin: 0 ;
  width:304px;
    margin:$global-lineheight*1rem auto $global-lineheight*1rem;
}  
