@mixin box-shadow($val){
  -o-box-shadow: $val;
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val;
}
@mixin border-radius($val){
  -moz-border-radius: $val;
  -webkit-border-radius: $val;
  -o-border-radius: $val;
  border-radius: $val;
}
@mixin placeholder{
  ::-moz-input-placeholder {
    @content;
  }
  ::-webkit-input-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}